import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-09d2d202"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "timelineChart" }
const _hoisted_2 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chart = _resolveComponent("chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", null, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_chart, {
        option: _ctx.refChartOption,
        style: _normalizeStyle({ height: _ctx.chartHeight })
      }, null, 8, ["option", "style"])
    ])
  ]))
}